import './App.css';

import { Spin } from 'antd';
import Amplify, { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import awsExports from './aws-exports';
import HeaderComponent from './components/HeaderComponent';
import MainSection from './components/MainSection';
import { AuthContext } from './Contexts';

function App() {
    const [cognitoPayload, setCognitoPayload] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    async function authUser() {
        try {
            let currSession = await Auth.currentSession();
            setCognitoPayload(currSession.getIdToken().payload);
        }
        catch (err) {
            if (err !== "No current user") { // no current user on page load
                alert(err);
            } else {
                Amplify.configure({
                    ...awsExports,
                    aws_appsync_authenticationType: "API_KEY",
                });
            }
        }
        finally {
            console.log(Amplify)
            setIsLoading(false);
        }
    };
    useEffect(() => { authUser() }, []);

    return (
        <Spin
            spinning={isLoading}
            tip="loading..."
        >
            <AuthContext.Provider value={{ cognitoPayload, setCognitoPayload, isLoading }}>
                <BrowserRouter>
                    <div id="app-body">
                        <HeaderComponent />
                        <main>
                            <MainSection />
                        </main>
                    </div>
                </BrowserRouter>
            </AuthContext.Provider>
        </Spin>
    );
};

export default App;
